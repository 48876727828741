<template>
    <div>
        <CustomHeader title="用户数据" @change="getData"></CustomHeader>
        <div class="layout">
        <el-row :gutter="20">
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">用户增长值</div>
                        <div class="item-content">
                            <div class="item-sub">
                                <div class="item-subtitle">昨日新增用户</div>
                                <dv-digital-flop :config="{number:[list.yesterday_user.data1],content:'{nt}人',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">本月新增用户</div>
                                <dv-digital-flop :config="{number:[list.yesterday_user.data2],content:'{nt}人',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
							<br>
                            <div class="item-sub">
                                <div class="item-subtitle">本年新增用户</div>
                                <dv-digital-flop :config="{number:[list.yesterday_user.data3],content:'{nt}人',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">累计增长用户</div>
                                <dv-digital-flop :config="{number:[list.yesterday_user.data4],content:'{nt}人',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-box1">
                            <div class="item-title">用户分类统计</div>
                            <div class="item-content">
                                <eCharts :options="option7"></eCharts>
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>             
            </el-col>
			
			
		
			
			
            <el-col :xs="24" :md="12">
                <dv-border-box-1>
                    <div class="item-box2">
                        <div class="item-title">全国用户分布</div>
                        <div class="item-content">
                            <eCharts :options="option6"></eCharts>
                        </div>
                    </div>
                </dv-border-box-1>
            </el-col>
			
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">用户来源城市</div>
                        <div class="item-content">
                            <dv-scroll-ranking-board :config="option1" style="width:85%;height:80%" />
                        </div>
                    </div>
                </dv-border-box-12>
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">性别占比</div>
                        <div class="item-content">
                           <eCharts :options="option2"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
			
			
			<el-col :xs="24" :md="24">
				<dv-border-box-12>
				    <div class="item-box1">
				        <div class="item-title">近30天用户变化趋势</div>
				        <div class="item-content">
				            <eCharts :options="option4"></eCharts>
				        </div>
				    </div>
				</dv-border-box-12>
			</el-col>
        </el-row>
    </div>
    </div>
</template>

<script>
    import {getUserInfo} from "../api/common";
    import eCharts from "../components/echarts"
    import {convertData,convertToLineData} from "../static/js/common";
    import CustomHeader from "../components/CustomHeader";
    import {mapState} from "vuex";
    export default {
        name: "User",
        components:{eCharts,CustomHeader},
        computed:{
            ...mapState(['linear_color','echarts_color1','echarts_color2','echarts_color3','echarts_color4','echarts_color5'])
        },
        data(){
            return{
                list:{
                    yesterday_user:{
                        data1:0,
                        data2:0,
                        data3:0,
                        data4:0
                    },
                },
                option1:{},//
                option2:{},//
                option3:{},//
                option4:{},//
                option6:{},//
                option7:{},//
                option4_list:[],
                option5_list:[],
                option4_index:0,
                option5_index:0,
            }
        },
        mounted() {
            this.getData()
            setInterval(()=>{
                this.getData()
            },60*1000)
            this.changeDate()
        },
        methods:{
            async getData(e){
                let res= await getUserInfo(e)
                this.list=res.data
                let symbols = [
                    'path://M18.2629891,11.7131596 L6.8091608,11.7131596 C1.6685112,11.7131596 0,13.032145 0,18.6237673 L0,34.9928467 C0,38.1719847 4.28388932,38.1719847 4.28388932,34.9928467 L4.65591984,20.0216948 L5.74941883,20.0216948 L5.74941883,61.000787 C5.74941883,65.2508314 11.5891201,65.1268798 11.5891201,61.000787 L11.9611506,37.2137775 L13.1110872,37.2137775 L13.4831177,61.000787 C13.4831177,65.1268798 19.3114787,65.2508314 19.3114787,61.000787 L19.3114787,20.0216948 L20.4162301,20.0216948 L20.7882606,34.9928467 C20.7882606,38.1719847 25.0721499,38.1719847 25.0721499,34.9928467 L25.0721499,18.6237673 C25.0721499,13.032145 23.4038145,11.7131596 18.2629891,11.7131596 M12.5361629,1.11022302e-13 C15.4784742,1.11022302e-13 17.8684539,2.38997966 17.8684539,5.33237894 C17.8684539,8.27469031 15.4784742,10.66467 12.5361629,10.66467 C9.59376358,10.66467 7.20378392,8.27469031 7.20378392,5.33237894 C7.20378392,2.38997966 9.59376358,1.11022302e-13 12.5361629,1.11022302e-13',
                    'path://M28.9624207,31.5315864 L24.4142575,16.4793596 C23.5227152,13.8063773 20.8817445,11.7111088 17.0107398,11.7111088 L12.112691,11.7111088 C8.24168636,11.7111088 5.60080331,13.8064652 4.70917331,16.4793596 L0.149791395,31.5315864 C-0.786976655,34.7595013 2.9373074,35.9147532 3.9192135,32.890727 L8.72689855,19.1296485 L9.2799493,19.1296485 C9.2799493,19.1296485 2.95992025,43.7750224 2.70031069,44.6924335 C2.56498417,45.1567684 2.74553639,45.4852068 3.24205501,45.4852068 L8.704461,45.4852068 L8.704461,61.6700801 C8.704461,64.9659872 13.625035,64.9659872 13.625035,61.6700801 L13.625035,45.360657 L15.5097899,45.360657 L15.4984835,61.6700801 C15.4984835,64.9659872 20.4191451,64.9659872 20.4191451,61.6700801 L20.4191451,45.4852068 L25.8814635,45.4852068 C26.3667633,45.4852068 26.5586219,45.1567684 26.4345142,44.6924335 C26.1636859,43.7750224 19.8436568,19.1296485 19.8436568,19.1296485 L20.3966199,19.1296485 L25.2043926,32.890727 C26.1862111,35.9147532 29.9105828,34.7595013 28.9625083,31.5315864 L28.9624207,31.5315864 Z M14.5617154,0 C17.4960397,0 19.8773132,2.3898427 19.8773132,5.33453001 C19.8773132,8.27930527 17.4960397,10.66906 14.5617154,10.66906 C11.6274788,10.66906 9.24611767,8.27930527 9.24611767,5.33453001 C9.24611767,2.3898427 11.6274788,0 14.5617154,0 L14.5617154,0 Z',
                ];
				
				let bodyMax = res.data['sex'][0]['value'] + res.data['sex'][1]['value']; //指定图形界限的值
				let labelSetting = {
				    show: true,
				    position: 'bottom',
				    offset: [0, -20],
				    formatter: (param)=> {
				        return (param.value / bodyMax * 100).toFixed(0) + '%';
				    },
				    textStyle: {
				        fontSize: 18,
				        fontFamily: 'Arial'
				    }
				};
				
				
                this.option4_list = [
                {
                    name: '新增人数',
                    type: 'line',
                    data: res.data['user_30']['new_user'],
                    color: this.echarts_color1
                },
				];

				this.option1={
				    data: res.data.city,
				    rowNum:3
				}
								
                this.option2 = {
                    tooltip: {
                        show:true
                    },
                    xAxis: {
                        data: ['男', '女'],
                        axisTick: {show: false},
                        axisLine: {show: false},
                        axisLabel: {show: false}
                    },
                    yAxis: {
                        max: bodyMax,
                        splitLine: {show: false},
                        axisTick: {show: false},
                        axisLine: {show: false},
                        axisLabel: {show: false}
                    },
                    grid: {
                        top:'10%',
                        left: '30%',
                        right: '30%',
                        bottom:0,
                        containLabel:true
                    },
                    series: [
                        {
                        name: 'typeA',
                        type: 'pictorialBar',
                        symbolClip: true,
                        symbolBoundingData: bodyMax,
                        label: labelSetting,
                        data: [
                            {
                                value: res.data['sex'][1]['value'],
                                symbol: symbols[0],
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(105,204,230)' //单独控制颜色
                                    }
                                },
                            },
                            {
                                value: res.data['sex'][0]['value'],
                                symbol: symbols[1],
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(255,130,130)' //单独控制颜色
                                    }
                                },
                            }
                        ],
                        //markLine: markLineSetting,
                        z: 10
                    },
                        {
                            // 设置背景底色，不同的情况用这个
                            name: '性别统计',
                            type: 'pictorialBar', //异型柱状图 图片、SVG PathData
                            symbolBoundingData: bodyMax,
                            animationDuration: 0,
                            itemStyle: {
                                normal: {
                                    color: '#ccc' //设置全部颜色，统一设置
                                }
                            },
                            z: 10,
                            data: [
                                {
                                    itemStyle: {
                                           normal: {
                                            color: 'rgba(105,204,230,0.40)' //单独控制颜色
                                        }
                                    },
                                    value: res.data['sex'][1]['value'],
                                    symbol: symbols[0]
                                },
                                {
                                    itemStyle: {
                                        normal: {
                                            color: 'rgba(255,130,130,0.40)' //单独控制颜色
                                        }
                                    },
                                    value: res.data['sex'][0]['value'],
                                    symbol: symbols[1]
                                }
                            ]
                        }
                    ]
                }
                
                this.option3={
                    data:res.data['language'],
                    radius: '65%',
                    activeRadius: '70%',
                    digitalFlopStyle: {
                        fontSize: 14
                    }
                }
               
				this.option4 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['新增人数'],
                            textStyle: {
                                color: 'white',
                                fontSize: 14
                            }
                        },
                        grid: {
                            top:'20%',
                            left: '7%',
                            right: '7%',
                            bottom:0,
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            boundaryGap: false,
                            data: res.data['user_30']['date']
                        },
                        yAxis: {
                            type: 'value',
                            splitNumber: 2,
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                        },
                        series: [{
                            name: '新增人数',
                            type: 'line',
                            data: res.data['user_30']['new_user'],
                            color: this.linear_color
                        }],
                        textStyle: {
                            color: 'white'
                        },
                    },
                    media: [ // 媒体查询
                        {
                            query: { // 这里写规则
                                maxWidth: 350
                            },
                            option: { // 这里写此规则满足下的 option
                                dataZoom: [//给x轴设置滚动条
                                    {
                                        type: 'inside',
                                        realtime: true,
                                        startValue: 0,
                                        endValue: 10
                                    }
                                ],
                                legend: {
                                    textStyle: {
                                        color: 'white',
                                        fontSize: 8
                                    }
                                },
                            }
                        },
                    ],
                };
      
				this.option6 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'item',
                            formatter: '{b}'
                        },
                        //顶部图例
                        legend: {
							orient: 'vertical',
							left: 'left',
							data:['用户数量'],
							textStyle:{
								color:'#fff',
							},
						},
                        //视觉映射组件
                        visualMap: {
                            left:'5%',
                            bottom: '5%',
                            text: ['高', '低'],
                            realtime: false,
                            calculable: true,
                            inRange: {
                                color:["#37A2DA"]
                            },
                            textStyle: {
                                color: '#fff',
                                fontSize: 14
                            }
                        },
                        geo: {
                            show: true,
                            map: "china",
                            layoutCenter: ['50%', '50%'],
                            layoutSize: '130%',
                            label: {
                                emphasis: {
                                    show: false
                                }
                            },
                            itemStyle: {
                                normal: {
                                    borderColor: 'rgba(147, 235, 248, 1)',
                                    borderWidth: 1,
                                    areaColor: {
                                        type: 'radial',
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.8,
                                        colorStops: [{
                                            offset: 0,
                                            color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                                        }, {
                                            offset: 1,
                                            color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                                        }],
                                        globalCoord: false // 缺省为 false
                                    },
                                    shadowColor: 'rgba(128, 217, 248, 1)',
                                    shadowOffsetX: -2,
                                    shadowOffsetY: 2,
                                    shadowBlur: 10
                                },
                                emphasis: {
                                    areaColor: '#389BB7',
                                    borderWidth: 0
                                }
                            },
							tooltip:{
								show:true,
								formatter:(params)=>{
									let city = res.data['province'].filter(item=>item.name===params.name);
									return params.name + '<br/>' + city[0].value + '人';
								}
							}
                        },
                        series: [
                            {
                                //文字和标志
                                name: '城市',
                                type: 'scatter',
                                coordinateSystem: 'geo',
                                data: convertData(res.data['province']),
                                symbolSize:10,
                                label: {
                                    normal: {
                                        formatter: '{b}{@value}人',
                                        position: 'right',
                                        show: true
                                    },
                                    emphasis: {
                                        show: true
                                    }
                                },
                                itemStyle: {
                                    normal: {
                                        color: '#37A2DA'
                                    }
                                }
                            },
                            //地图点的动画效果
                            {
                                //  name: 'Top 5',
                                type: 'effectScatter',
                                coordinateSystem: 'geo',
                                data: convertData(res.data['province'].sort((a, b)=> {
                                    return b.value - a.value;
                                }).slice(0, 10)),
                                symbolSize: (val)=> {
                                    return val[2] > 1000?25:val[2] / 50;
                                },
                                showEffectOn: 'render',
                                rippleEffect: {
                                    brushType: 'stroke'
                                },
                                hoverAnimation: true,
                                itemStyle: {
                                    normal: {
                                        color: '#37A2DA',
                                        shadowBlur: 10,
                                        shadowColor: '#37A2DA'
                                    }
                                },
                                zlevel: 1
                            },
                            //飞线
                            {
                                type: 'lines',
                                name:'路径',
                                zlevel: 2,
                                effect: {
                                    show: true,
                                    period: 4, //箭头指向速度，值越小速度越快
                                    trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
                                    symbol: 'arrow', //箭头图标
                                    symbolSize: 3, //图标大小
                                },
                                lineStyle: {
                                    normal: {
                                        color: '#37A2DA',
                                        width: 0.1, //尾迹线条宽度
                                        opacity: 0.5, //尾迹线条透明度
                                        curveness: .3 //尾迹线条曲直度
                                    }
                                },
                                data: convertToLineData(res.data['province'], [107.25, 35.43])
                            },
                        ],
                    },
                    media: [ // 媒体查询
                        {
                            query: { // 这里写规则
                                maxWidth: 350
                            },
                            option: { // 这里写此规则满足下的 option
                                visualMap:{
                                    show:false
                                },
                            }
                        },
                    ],
                };
            	
				var cate_name = [];
				for(var i in res.data.cate){
					cate_name[i] = res.data.cate[i].name;
				}

				this.option7 = {
					
				    baseOption:{
				        tooltip: {
				            trigger: 'axis',
				            axisPointer: { // 坐标轴指示器，坐标轴触发有效
				                type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
				            }
				        },
				        legend: {
				            data: ['分类统计'],
				            textStyle: {
				                color: 'white',
				                fontSize: 14
				            }
				        },
				        grid: {
				            top:'20%',
				            left: '5%',
				            right: '5%',
				            bottom:'0',
				            containLabel: true
				        },
				        xAxis: [{
				            type: 'category',
				            data: cate_name,
				            axisLine: {
				                lineStyle: {
				                    color: 'white'
				                }
				            },
				            axisLabel: {
				                fontSize: 14
				            },
				        }],
				        yAxis: [{
				            type: 'value',
				            splitNumber: 1,
				            axisLine: {
				                show: false
				            },
				            axisLabel: {
				                fontSize: 14
				            },
				            splitLine:{//分割线
				                show:false
				            }
				        }],
				        series: [{
				            name: '用户数量',
				            type: 'bar',
				            stack: '用户数量',
				            barWidth: '25px',
				            label: {
				                show: true,
				                position: 'top'
				            },
				            data: res.data.cate,
				            color: this.linear_color
				        }, ],
				        textStyle: {
				            color: 'white'
				        },
				    },
				};
			
			},
            changeDate(){
                setInterval(()=>{
                    this.option4_index=0;
                    this.option4.baseOption.series=this.option4_list[this.option4_index];
                },5000)
            },
            formatter(number) {
                const numbers = number.toString().split('').reverse()
                const segs = []
                while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
                return segs.join(',').split('').reverse().join('')
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/static/css/common";
</style>
